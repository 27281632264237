@font-face {
  font-family: "AlbraGroteskReg";
  src: url("../fonts/Albra-Grotesk-Regular.woff2") format("woff2"),
    url("../fonts/Albra-Grotesk-Regular.woff") format("woff");
}

@font-face {
  font-family: "AlbraItalic";
  src: url("../fonts/Albra-Regular-Italic.woff2") format("woff2"),
    url("../fonts/Albra-Regular-Italic.woff") format("woff");
}

body {
  font-family: "AlbraGroteskReg", serif;
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "AlbraGroteskReg", serif;
  font-weight: 500;

  .emph {
    font-family: "AlbraItalic", serif;
  }
}

p {
  font-family: "AlbraGroteskReg", cursive;
  line-height: 1.5rem;
}

button,
input,
select,
textarea {
  font-family: "AlbraGroteskReg", cursive;
}
