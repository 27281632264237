.offline-view {
  display: flex;
  min-height: 100svh;
  background-color: $linnen;

  &__text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &--logo {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5rem 3rem 3rem;

      img {
        width: 100%;
        max-width: 230px;
      }
    }

    &--contact {
      color: $darkgreen;
      text-align: center;
      font-weight: 600;
      padding: 0 3rem 3rem 3rem;
      width: 100%;
      max-width: 500px;
      margin: 0 auto;

      a {
        display: inline-block;
        color: $darkgreen;
        font-weight: 800;
      }

      p {
        margin-top: 2rem;
      }
    }
  }

  &__image {
    background-image: url("../../assets/images/KoffieVanKai_pattern_inverted_RGB_orange.png");
    background-size: 70%;
  }
}
