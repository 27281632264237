.comingsoon {
  display: grid;
  grid-template-columns: 3fr 2fr;
  min-height: 100vh;
  background-color: $linnen;

  &__text {
    height: 100%;
    display: flex;
    flex-direction: column;
    &--logo {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $darkgreen;
      padding: 3rem;

      img {
        max-width: 230px;
      }
    }

    &--slider {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $linnen;
      color: $darkgreen;
      text-align: center;
      padding: 7rem 3rem 3rem 3rem;

      p {
        font-weight: 600;
        margin-bottom: 3rem;
      }

      .slick-slider {
        width: 100%;
        max-width: 500px;
      }
    }

    &--contact {
      color: $darkgreen;
      text-align: center;
      font-weight: 600;
      padding: 0 3rem 3rem 3rem;
      width: 100%;
      max-width: 500px;
      margin: 0 auto;

      a {
        display: inline-block;
        color: $purple;
        font-weight: 800;
      }
    }
  }

  &__image {
    background-image: url("../../assets/images/KoffieVanKai_pattern_inverted_RGB_orange.png");
    background-size: 70%;
  }
}

@media screen and (max-width: 1024px) {
  .comingsoon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__image {
      width: 100%;
      height: 250px;
      background-size: 60%;
    }
  }
}

@media screen and (max-width: 765px) {
  body {
    background-color: $linnen;
  }
  .comingsoon {
    min-height: unset;
    &__text {
      &--logo {
        padding: 2rem;

        img {
          max-width: 180px;
        }
      }
      &--slider {
        padding: 3rem 1.5rem;

        p {
          margin-bottom: 3rem;
          br {
            display: none;
          }
        }

        .slick-slider {
          max-width: unset;

          h1 {
            font-size: 20px;
          }
        }
      }

      &--contact {
        max-width: unset;
        padding: 0 1.5rem 3rem 1.5rem;
      }
    }

    &__image {
      display: none;
    }
  }
}
