// COLORS:

$darkgreen: #635420;
$purple: #ded1ec;
$pink: #fbd7c9;
$red: #562c2c;
$lightgreen: #9b8934;
$linnen: #fff8f3;
$black: #2b2b2b;
$white: #ffffff;

// FONTS:

$bodyFont: "AlbraGroteskReg", cursive;
$headerFont: "AlbraGroteskReg", cursive;
$headerFontEmph: "AlbraSemi", cursive;
